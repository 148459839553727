<template>
  <div class="border__footer">
    <div class="footer">
      <div class="footer__item">
        <ul class="footer__list">
          <li class="footer__list--item">
            <a target="_blank" href="https://homefunds.com/#work">
              How it Works
            </a>
          </li>
          <li class="footer__list--item">
            <a target="_blank" href="https://homefunds.com/#TheProcess">
              The Process
            </a>
          </li>
          <li class="footer__list--item">
            <a target="_blank" href="https://homefunds.com/faq/">FAQ</a>
          </li>
          <li class="footer__list--item">
            <a target="_blank" href="https://homefunds.com/privacy-policy/"
              >Privacy Policy</a
            >
          </li>
          <li class="footer__list--item">
            <a target="_blank" href="https://homefunds.com/term-of-service/"
              >Terms of Use</a
            >
          </li>
        </ul>
        <div class="grupe__footer">
          <div class="contact__us">
            <div class="contact__us--item">
              <img src="../assets/icons/msg.svg" alt="" />
              <p>
                <a href="mailto:hello@homefunds.com">hello@homefunds.com</a>
              </p>
            </div>
            <div class="contact__us--item">
              <img src="../assets/icons/call.svg" alt="" />
              <p><a href="tel:+14243009939">424-300-9939</a></p>
            </div>
          </div>
          <div class="footer__company">
            <img src="../assets/icons/company-logo.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="footer__item order">
        <div class="logo">
          <a href="https://homefunds.com/" target="_blank">
            <img src="../assets/icons/big__logo.svg" alt="logo" />
          </a>
          <p>
            HomeFunds.com is dedicated to getting homeowners access to their
            equity - faster and simpler - and with superior customer service
            along the way.
          </p>
        </div>
        <div class="social">
          <a
            class="social__item"
            href="https://www.linkedin.com/company/homefunds"
            target="_blank"
          >
            <img src="../assets/icons/linkedin.svg" alt="" />
          </a>
          <a
            class="social__item"
            href="https://www.facebook.com/HomeFunds"
            target="_blank"
          >
            <img src="../assets/icons/facebook.svg" alt="" />
          </a>
          <a
            class="social__item"
            href="https://twitter.com/Home_Funds"
            target="_blank"
          >
            <img src="../assets/icons/twitter.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 51px;
}

.footer__list {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 39px;
  padding-bottom: 48px;

  &--item {
    font-family: "Source Sans Pro SemiBold";
    color: #e8e8fe;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grupe__footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.contact__us {
  display: flex;
  flex-direction: column;
  width: 187px;
  padding-top: 51px;
  &--item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    img {
      margin-right: 11px;
    }
  }
}

.footer__company {
  margin-top: 40px;
  padding: 20px;
  margin-bottom: 58px;
  border-radius: 6px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
}

.logo {
  margin-bottom: 24px;
  p {
    margin: 0 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
  }

  img {
    margin-bottom: 18px;
  }
}

.social {
  padding-bottom: 47px;
  .social__item {
    margin-right: 28px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: 940px) {
  .border__footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .before__footer {
    padding-bottom: 71px;
  }

  .footer {
    max-width: 1180px;
    display: flex;
    margin: 0 auto;
    padding: 60px 20px 38px;
  }

  .footer__item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    order: 1;
  }

  .footer__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    padding-top: 13px;
    padding-bottom: 0;

    .footer__list--item {
      margin-bottom: 0;
    }
  }

  .contact__us {
    padding: 0;
  }

  .footer__company {
    margin: 0;
    margin-left: 77px;
    img {
      width: 59px;
      height: 55px;
    }
  }

  .grupe__footer {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  }

  .order {
    order: 0;
    text-align: left;
  }

  .social {
    text-align: left;
    width: 300px;
    margin-left: 58px;
  }

  .logo p {
    width: 320px;
    text-align: left;
    margin-left: 62px;
  }
}
</style>
