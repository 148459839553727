<template>
  <div class="app-bg">
    <div id="app">
      <All />

      <div>
        <div id="hubspotForm" />
      </div>
    </div>
  </div>
</template>

<script>
import All from "./components/All.vue";

export default {
  name: "App",
  components: {
    All
  }
};
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  list-style: none;
}

#app {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  height: 100%;
  font-family: "Mark Pro Regular";
  font-size: 16px;
  text-align: center;
  color: #f2f0f9;
  background-image: url("./assets/intro.png");
  background-position: top;
  background-repeat: no-repeat;
}

.app_bg_witdh100 {
  background: linear-gradient(0deg, #004e86 100%, rgba(40, 62, 105, 0.4) 109%);
}

.app-bg {
  background: linear-gradient(0deg, #004e86 100%, rgba(40, 62, 105, 0.4) 109%);
}

@media (min-width: 768px) {
  .app-bg {
    background: linear-gradient(
      0deg,
      #004e86 66.56%,
      rgba(40, 62, 105, 0.4) 109%
    );
  }
}

@media (min-width: 940px) {
  .app-bg {
    background: linear-gradient(
      0deg,
      #004e86 56.56%,
      rgba(40, 62, 105, 0.4) 109%
    );
  }
}

@media (min-width: 1440px) {
  #app {
    background-size: contain;
  }

  .app-bg {
    background: linear-gradient(
      0deg,
      #004e86 55.56%,
      rgba(40, 62, 105, 0.4) 109%
    );
  }
}

@media (min-width: 1560px) {
  .app-bg {
    background: linear-gradient(
      0deg,
      #004e86 52.56%,
      rgba(40, 62, 105, 0.4) 109%
    );
  }
}

@media (min-width: 1680px) {
  .app-bg {
    background: linear-gradient(
      0deg,
      #004e86 49.56%,
      rgba(40, 62, 105, 0.4) 109%
    );
  }
}
</style>
