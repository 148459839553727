<template>
  <div :class="{ app_bg_witdh100: page === 3 || page === 4 }">
    <!-- header -->
    <div class="border">
      <header class="header">
        <div class="logo mobail">
          <a href="https://homefunds.com/" target="_blank">
            <img src="../assets/icons/logo.svg" alt="logo" />
          </a>
        </div>
        <div class="logo width-auto desktop">
          <a href="https://homefunds.com/" target="_blank">
            <img src="../assets/icons/big__logo.svg" alt="logo" />
          </a>
        </div>
        <div class="pages mobail">
          <span
            v-if="pageFirst !== 1"
            class="page"
            :class="{
              confirm__page: pageFirst === 1,
              active__page: page === 1
            }"
          >
            01
          </span>

          <span
            v-if="pageFirst === 1"
            class="page"
            :class="{
              confirm__page: pageFirst === 1,
              active__page: page === 1
            }"
          >
            <img src="../assets/icons/ok.svg" alt="" />
          </span>
          <!--  -->
          <span
            v-if="pageSecond !== 2"
            class="page"
            :class="{
              confirm__page: pageSecond === 2,
              active__page: page === 2
            }"
          >
            02
          </span>

          <span
            v-if="pageSecond === 2"
            class="page"
            :class="{
              confirm__page: pageSecond === 2,
              active__page: page === 2
            }"
          >
            <img src="../assets/icons/ok.svg" alt="" />
          </span>
          <!--  -->
          <span
            v-if="pageThird !== 3 && pageFour !== 4"
            class="page"
            :class="{
              confirm__page: pageThird === 3,
              active__page: page === 3 && pageThird !== 3
            }"
          >
            03
          </span>

          <span
            v-if="pageThird === 3"
            class="page"
            :class="{
              confirm__page: pageThird === 3,
              active__page: page !== 3
            }"
          >
            <img src="../assets/icons/ok.svg" alt="" />
          </span>

          <span
            v-if="pageFour === 4 && page === 4"
            class="page"
            :class="{
              confirm__page: page === 4
            }"
          >
            <img src="../assets/icons/ok.svg" alt="" />
          </span>
        </div>

        <div class="pages desktop" v-if="pageThird !== 3">
          <div class="page__number__item">
            <span class="page__count">01</span>
            <span
              class="page"
              :class="{
                confirm__page: pageFirst === 1,
                active__page: page === 1
              }"
            >
              <img v-if="pageFirst === 1" src="../assets/icons/ok.svg" alt=""
            /></span>
          </div>
          <span
            class="completed  33%"
            :class="{ completed__border: pageFirst === 1 }"
            >33% Completed</span
          >

          <div class="page__number__item">
            <span class="page__count">02</span>
            <span
              class="page"
              :class="{
                confirm__page: pageSecond === 2,
                active__page: page === 2
              }"
            >
              <img
                v-if="pageSecond === 2"
                src="../assets/icons/ok.svg"
                alt=""
              />
            </span>
          </div>
          <span
            class="completed 66%"
            :class="{
              completed__border: pageSecond === 2,
              noAccentBorder: page === 1
            }"
            >66% Completed</span
          >

          <div class="page__number__item">
            <span class="page__count">03</span>
            <span
              class="page"
              :class="{
                confirm__page: pageThird === 3 || pageFour === 4,
                active__page: page === 3 && pageThird !== 3
              }"
            >
              <img
                v-if="pageThird === 3 || pageFour === 4"
                src="../assets/icons/ok.svg"
                alt=""
              />
            </span>
          </div>
        </div>
        <ul v-if="pageThird === 3" class="listPageThird">
          <li>
            <a target="_blank" href="https://homefunds.com/#work">
              How it Works
            </a>
          </li>
          <li>
            <a target="_blank" href="https://homefunds.com/#TheProcess">
              The Process
            </a>
          </li>
          <li><a target="_blank" href="https://homefunds.com/faq/">FAQ</a></li>
        </ul>
        <!-- <div class=" "> -->
        <a href="tel:+14243009939" class="header__contact mobail"
          ><img src="../assets/icons/phone.svg" alt="phone"
        /></a>
        <!-- </div> -->
        <div class="contact__us desktop">
          <div class="contact__us--item">
            <img src="../assets/icons/call.svg" alt="" />
            <p><a href="tel:+14243009939">424-300-9939</a></p>
          </div>
          <div class="contact__us--item">
            <img src="../assets/icons/msg.svg" alt="" />
            <p>
              <a href="mailto:hello@homefunds.com">hello@homefunds.com</a>
            </p>
          </div>
        </div>
      </header>
    </div>
    <!-- step 1 -->
    <div class="pt-36" v-if="page === 1">
      <div class="page__counter">
        <span>1</span>
      </div>

      <h2 class="intro__title">
        Is my home eligible for a home investment of up to $300,000?
      </h2>

      <form class="form page__first" @submit.prevent="handleSumbmit()">
        <div class="form__item first">
          <h3 class="item__title">Address</h3>
          <div class="input__container width__70">
            <label
              v-if="focusedAddress || $v.address.$model || $v.address.$error"
              class="label"
            >
              Address
            </label>

            <div class="input__container">
              <vue-google-autocomplete
                :class="{
                  focused: focusedAddress,
                  valid: $v.address.$model && !$v.address.$error,
                  invalid: $v.address.$error,
                  invalid__container: $v.address.$error,
                  valid__container: $v.address.$model,
                  focused__container: focusedAddress
                }"
                @focus="focusedAddress = true"
                @blur="focusedAddress = false"
                class="input mb-0"
                type="text"
                placeholder="Address"
                country="usa"
                v-on:placechanged="getAddressData"
                id="map"
                ref="address"
              >
              </vue-google-autocomplete>
            </div>
            <div>
              <div class="error__msg" v-if="$v.address.$error">
                Field is required
              </div>
            </div>
          </div>

          <div class="input__container width__27">
            <label v-if="focusedUnit || unit" class="label">
              Unit
            </label>
            <div class="input__container">
              <input
                v-model.trim="unit"
                :class="{
                  focused: focusedUnit,
                  valid: unit,
                  valid__container: unit,
                  focused__container: focusedUnit
                }"
                @focus="focusedUnit = true"
                @blur="focusedUnit = false"
                class="input mb-0"
                type="text"
                placeholder="Unit (optional)"
              />
            </div>
          </div>
        </div>

        <div class="form__item second">
          <h3 class="item__title">Type of property</h3>

          <div class="radio__grupe" v-for="item in radioObj" :key="item.id">
            <label class="radio" :for="item.id">
              <span
                class="radio__input"
                :class="{
                  radio__input__valid: item.id === radioSelceted
                }"
              >
                <input
                  type="radio"
                  :id="item.id"
                  :value="item.value"
                  v-model="$v.radio.$model"
                  @change="changeRadioInput(item.id)"
                />
                <span class="radio__control"></span>
              </span>
              <span
                :class="{
                  radio__invalid: !$v.radio.$model && $v.radio.$error,
                  radio__valid: item.id === radioSelceted
                }"
                class="radio__label"
              >
                {{ item.value }}
              </span>
            </label>
          </div>
        </div>

        <div class="form__item third">
          <h3 class="item__title">Personal information</h3>
          <div class="input__container input_mb">
            <label
              v-if="
                focusedFName || $v.first__name.$model || $v.first__name.$error
              "
              class="label"
            >
              First name
            </label>
            <div class="input__container">
              <input
                v-model.trim="$v.first__name.$model"
                :class="{
                  focused: focusedFName,
                  valid: $v.first__name.$model && !$v.first__name.$error,
                  invalid: $v.first__name.$error,
                  invalid__container: $v.first__name.$error,
                  valid__container: $v.first__name.$model,
                  focused__container: focusedFName
                }"
                @focus="focusedFName = true"
                @blur="focusedFName = false"
                class="input"
                type="text"
                placeholder="First name"
              />
            </div>
            <div>
              <div class="error__msg" v-if="$v.first__name.$error">
                Field is required
              </div>
            </div>
          </div>

          <div class="input__container input_mb">
            <label
              v-if="
                focusedLName || $v.last__name.$model || $v.last__name.$error
              "
              class="label"
            >
              Last name
            </label>
            <div class="input__container ">
              <input
                v-model.trim="$v.last__name.$model"
                :class="{
                  focused: focusedLName,
                  valid: $v.last__name.$model && !$v.last__name.$error,
                  invalid: $v.last__name.$error,
                  invalid__container: $v.last__name.$error,
                  valid__container: $v.last__name.$model,
                  focused__container: focusedLName
                }"
                @focus="focusedLName = true"
                @blur="focusedLName = false"
                class="input"
                type="text"
                placeholder="Last name"
              />
            </div>
            <div>
              <div class="error__msg" v-if="$v.last__name.$error">
                Field is required
              </div>
            </div>
          </div>

          <div class="input__container input_mb">
            <label
              v-if="focusedEmail || $v.email.$model || $v.email.$error"
              class="label"
            >
              Email
            </label>
            <div class="input__container ">
              <input
                v-model.trim="$v.email.$model"
                :class="{
                  focused: focusedEmail,
                  valid: $v.email.$model && !$v.email.$error,
                  invalid: $v.email.$error,
                  invalid__container:
                    ($v.email.$error && $v.email.email && !$v.email.required) ||
                    $v.email.$error,
                  valid__container: $v.email.$model,
                  focused__container: focusedEmail
                }"
                @focus="focusedEmail = true"
                @blur="focusedEmail = false"
                class="input"
                type="text"
                placeholder="Email"
              />
            </div>
            <div class="error__msg" v-if="$v.email.$error && !$v.email.email">
              Field is Email
            </div>
            <div
              class="error__msg"
              v-if="$v.email.$error && !$v.email.required"
            >
              Field is required
            </div>
          </div>

          <div class="input__container input_mb">
            <label
              v-if="focusedPhone || $v.phone.$model || $v.phone.$error"
              class="label"
            >
              Phone number
            </label>
            <div class="input__container ">
              <input
                v-mask="'###-###-####'"
                v-model.trim="$v.phone.$model"
                :class="{
                  focused: focusedPhone,
                  valid: $v.phone.$model && !$v.phone.$error,
                  invalid: $v.phone.$error,
                  invalid__container: $v.phone.$error,
                  valid__container: $v.phone.$model,
                  focused__container: focusedPhone
                }"
                @focus="focusedPhone = true"
                @blur="focusedPhone = false"
                class="input"
                type="text"
                placeholder="Phone number"
              />
            </div>
            <div>
              <div
                class="error__msg"
                v-if="$v.phone.$error && !$v.phone.minLength"
              >
                Phone number must have at least 10 numbers.
              </div>
              <div
                class="error__msg"
                v-if="$v.phone.$error && $v.phone.minLength"
              >
                Field is required
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn__submit">
          Check My Eligibility
        </button>
      </form>
    </div>
    <!-- step 2 -->
    <div class="pt-36" v-if="page === 2">
      <div class="page__counter">
        <span>2</span>
      </div>
      <h2 class="intro__title width__613">
        Your address location is eligible for a HomeFunds investment
      </h2>
      <form class="form page__second" @submit.prevent="handleSumbmit1()">
        <div class="form__item intro__second--page">
          <div class="intro__page__second">
            <img src="../assets/icons/homeZillowApi.svg" alt="" />
            <div>
              <h2 class="title__zillow__cost">Zillow Estimated Home Value:</h2>
            </div>
          </div>
          <div class="input__container input_mb homeValue">
            <label v-if="focusedHomeValue || homeValue" class="label">
              Confirm home value
            </label>

            <div class="input__container">
              <input
                v-mask="mask"
                v-model.trim="homeValue"
                :class="{
                  focused: focusedHomeValue,
                  valid: homeValue,
                  valid__container: homeValue,
                  focused__container: focusedHomeValue
                }"
                @focus="focusedHomeValueInput()"
                @blur="bluredHomeValue()"
                class="input"
                type="text"
                placeholder="Confirm home value"
                ref="homeValue"
              />
            </div>
          </div>
          <div style="font-size:13px; color:#a7aabf; text-align:left;">
             See more details for this address on Zillow.  Data is provided as-is via the Zestimate API.
          </div>
        </div>
        <!--  -->
        <div class="form__item">
          <h3 class="title__about">
            Please indicate the total debt balance on your home
          </h3>
          <p class="text__about">
            We use this to determine the maximum investment amount eligible.
          </p>
          <div class="input__container input_mb">
            <label
              v-if="focusedDebet || $v.debet.$model || $v.debet.$error"
              class="label"
            >
              Debt balance *
            </label>

            <div class="input__container">
              <input
                v-mask="mask"
                v-model.trim="$v.debet.$model"
                :class="{
                  focused: focusedDebet,
                  valid: $v.debet.$model && !$v.debet.$error,
                  invalid: $v.debet.$error,
                  invalid__container: $v.debet.$error,
                  valid__container: $v.debet.$model,
                  focused__container: focusedDebet
                }"
                @focus="focusedDebet = true"
                @blur="focusedDebet = false"
                class="input"
                type="text"
                placeholder="Debt balance *"
              />
            </div>

            <div>
              <div class="error__msg" v-if="$v.debet.$error">
                Field is required
              </div>
            </div>
          </div>
          <p class="shoud__know">
            * This figure should include your primary mortgage and any home
            equity loans/HELOCS.
          </p>
        </div>
        <button type="submit" class="btn__submit table__btn">
          NEXT
        </button>
      </form>
      <div style="margin-bottom:20px; font-size:12px; color:#a7aabf; text-align:center;">
        &copy; Zillow, Inc., 2006-2021. Use is subject to Terms of Use.
      </div>
    </div>
    <!-- step 3 -->
    <div v-if="page === 3 && pageThird !== 3">
      <h2 class="intro__title page3">
        CONGRATULATIONS!
      </h2>
      <form class="page__third" @submit.prevent="handleSumbmit2()">
        <img
          class="yellow__ball"
          src="../assets/icons/ball__yellow.svg"
          alt=""
        />
        <img
          class="white__ball"
          src="../assets/icons/ball__white.svg"
          alt="ball__white"
        />
        <img
          class="green__ball"
          src="../assets/icons/ball__greenLight.svg"
          alt="ball__white"
        />
        <img
          class="purpule__ball"
          src="../assets/icons/ball__purpule.svg"
          alt="ball__white"
        />
        <img
          class="bottom__balls--right"
          src="../assets/icons/right__balls.svg"
          alt=""
        />
        <img
          class="bottom__balls--left"
          src="../assets/icons/left__ballls.svg"
          alt=""
        />
        <img
          class="first__flag"
          src="../assets/icons/flags__left.svg"
          alt="flags"
        />
        <img
          class="second__flag"
          src="../assets/icons/flags__right.svg"
          alt="flags"
        />
        <img
          class="bigFirstFlag"
          src="../assets/icons/bigFlags__left.svg"
          alt="flags"
        />
        <img
          class="bigSecondFlag"
          src="../assets/icons/bigFlags__right.svg"
          alt="flags"
        />
        <h2 class="last__page--title">
          We are able to prequalify your home investment for up to:
        </h2>
        <div class="border">
          <div class="saved__money">
            <img
              class="banknotes"
              src="../assets/icons/banknotes.svg"
              alt="banknotes"
            />
            <span class="price">
              ${{ sumValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </span>
          </div>
        </div>
        <div class="main__info">
          <div class="info__item">
            <img src="../assets/icons/ball__yellow.svg" alt="" />
            <p class="info__text">No added debt</p>
          </div>
          <div class="info__item">
            <img src="../assets/icons/ball__greenLight.svg" alt="" />
            <p class="info__text">No interest payments</p>
          </div>
          <div class="info__item">
            <img src="../assets/icons/ball__purpule.svg" alt="" />
            <p class="info__text">No prepayment penalties</p>
          </div>
          <div class="info__item">
            <img src="../assets/icons/ball__red.svg" alt="" />
            <p class="info__text">Continue to enjoy your home</p>
          </div>
          <div class="info__item">
            <img src="../assets/icons/ball__white.svg" alt="" />
            <p class="info__text">Use cash for any purpose</p>
          </div>
          <div class="info__item">
            <img src="../assets/icons/ball__greanDark.svg" alt="" />
            <p class="info__text">Quick funding</p>
          </div>
        </div>
        <div class="text__info">
          <p>
            Access Cash Today from your Home’s Value. Unlike a loan, there is no
            added debt, no monthly payments and no interest. As your home value
            changes, so does HomeFund’s share in the home appreciation or
            depreciation.
          </p>
        </div>

        <button type="submit" class="btn__submit">
          Complete Funding Process
        </button>
      </form>

      <div class="last__page before__footer">
        <div class="feedback">
          <div class="avatar__info">
            <img class="avatar" src="../assets/avatar.png" alt="" />
            <img class="avatar__icon" src="../assets/icons/ok.svg" alt="" />
            <div class="title__stars">
              <h3 class="avatar__title">Gerald V.</h3>
              <span class="star" v-for="(item, index) in 5" :key="index">
                <img src="../assets/icons/star.svg" alt="" />
              </span>
            </div>
          </div>

          <p class="avatar__text">
            “My wife has been talking about upgrading the kitchen for years. But
            I think this way the kitchen renovation will increase the home
            value.”
          </p>
        </div>

        <div class="company">
          <img src="../assets/icons/company-logo.svg" alt="" />
        </div>
      </div>
    </div>
    <!-- KEK -->
    <div v-if="page === 4" class="page4">
      <h2 class="intro__title page3">
        Your Estimate
      </h2>
      <div class="form">
        <p class="page4__text">
          Thanks for completing an application. It seems you may not have enough
          equity in the home to access with HomeFunds. We will reach out if our
          investment criteria changes in the future. Thank you.
        </p>
      </div>
    </div>
    <div v-if="pageThird === 3">
      <h2 class="intro__title fz-28 page3 pb-0">
        Book a Home Value Consultation
      </h2>
      <p class="sub__title">
        Have Any Questions Answered. Complete Your Investment Application.
      </p>
      <div
        class="meetings-iframe-container"
        data-src="https://homefunds.zohobookings.com/portal-embed#/customer/4159327000000024886"
      >
        <iframe width='80%' height='900px' src='https://homefunds.zohobookings.com/portal-embed#/customer/4159327000000024886' frameborder='0' allowfullscreen='' > </iframe>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false
});

import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    VueGoogleAutocomplete,
    Footer
  },
  data() {
    return {
      mask: currencyMask,
      focusedAddress: false,
      focusedUnit: false,
      focusedRadio: false,
      focusedFName: false,
      focusedLName: false,
      focusedEmail: false,
      focusedPhone: false,
      focusedDebet: false,
      focusedHomeValue: false,

      // data form
      address: null,
      first__name: null,
      unit: null,
      radio: null,
      last__name: null,
      email: null,
      phone: null,
      homeValue: "",
      // 1,050,000
      debet: "",

      sumValue: "",
      orysia: {},
      // radioObj
      radioObj: [
        {
          id: 1,
          value: "Single Family Home"
        },
        {
          id: 2,
          value: "Multi Family Home"
        },
        {
          id: 3,
          value: "Condo, Apartment or Townhouse"
        },
        {
          id: 4,
          value: "Co-Op"
        }
      ],
      page: 1,
      radioSelceted: null,
      pageFirst: null,
      pageSecond: null,
      pageThird: null,
      pageFour: null
    };
  },
  validations: {
    debet: {
      required
    },
    address: {
      required
    },
    first__name: {
      required
    },
    last__name: {
      required
    },
    email: {
      required,
      email: email
    },
    phone: {
      required,
      minLength: minLength(12)
    },
    radio: {
      required
    }
  },
  watch: {
    page: function(newQuestion) {
      if (newQuestion === 2) {
        this.$nextTick(() => {
          this.$refs["homeValue"].focus();
          if (this.homeValue.length > 2) {
            return;
          }
          this.homeValue = "$";
          //console.log(this.$refs);
        });
      }
    }
  },
  methods: {
    bluredHomeValue() {
      this.focusedHomeValue = false;
      if (this.homeValue === "$") {
        this.homeValue = "";
      }
    },
    focusedHomeValueInput() {
      this.focusedHomeValue = true;
    },
    startNewStepByStep() {
      (this.pageFirst = null),
        (this.pageSecond = null),
        (this.pageThird = null),
        (this.page = 1),
        (this.address = null),
        (this.unit = null),
        (this.radio = null),
        (this.first__name = null),
        (this.last__name = null),
        (this.email = null),
        (this.debet = ""),
        (this.homeValue = ""),
        (this.phone = null);
    },
    async getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData);
      //console.log(addressData);
      console.log(id);
      this.address = addressData;

      const sumHome = await axios
        .get(
          `https://api.bridgedataoutput.com/api/v2/zestimates?access_token=0ac0d3ecc5c82cb96a79c3b7571be22a&address="${addressData.street_number +
            ", " +
            addressData.route +
            ", " +
            addressData.locality +
            ", " +
            addressData.postal_code}"`
        )
        .then(res => res.data);

      if (sumHome.total === 0) {
        this.homeValue = "";
        //console.log("this.homeValue", this.homeValue);
        return;
      } else if (!sumHome.bundle[0].zestimate) {
        this.homeValue = "";
      } else if (sumHome.bundle[0].zestimate) {
        this.homeValue = sumHome.bundle[0].zestimate;
      }
      //console.log("this.homeValue", this.homeValue);
    },
    changeRadioInput(id) {
      this.radioSelceted = id;
    },
    handleSumbmit() {
      // form 1
      if (
        !this.address ||
        !this.radio ||
        !this.first__name ||
        !this.last__name ||
        !this.email ||
        this.$v.phone.$error === true
      ) {
        //console.log("Nosubmit!");
        this.$v.$touch();
        return;
      } else {
        //console.log("submit!");
        const data = {
          address: this.address,
          unit: this.unit,
          radio: this.radio,
          first__name: this.first__name,
          last__name: this.last__name,
          email: this.email,
          phone: this.phone
        };

        this.orysia = {
          ...data,
          address: `${this.address.country}/${this.address.route} ${this.address.street_number ? this.address.street_number : 'without street'}/${
            this.address.locality
          }/${this.address.postal_code ? this.address.postal_code : ""}`
        };
        this.pageFirst = 1;
        this.page = 2;
      }
    },
    handleSumbmit1() {
      // form 2
      //console.log(this.$v);
      if (!this.debet) {
        //console.log("Nosubmit!");
        this.$v.$touch();
        return;
      } else {
        //console.log("submit!");

        const data = {
          debet: +this.debet.replace(/[^-0-9]/gim, ""),
          homeValue: +this.homeValue.replace(/[^-0-9]/gim, "")
        };

        if ((data.homeValue - data.debet - 80000) >= 25000) {
          if ((data.homeValue - data.debet - 80000) > 150000) {
              this.sumValue = 150000; 
          } else {
              this.sumValue = (data.homeValue - data.debet - 80000);
          }
          this.page = 3;
        } else {
          this.sumValue = data.homeValue - data.debet;
          this.page = 4;
          this.pageFour = 4;
        }
        this.pageSecond = 2;

        this.orysia.debet = data.debet;
        this.orysia.homeValue = data.homeValue;
        axios.post(`https://api.homefunds.com/admin-email`, this.orysia)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
          //console.log(this.orysia);
      }
    },
    handleSumbmit2() {
      // form 3(last page)
      //console.log(this.$v);
      this.pageThird = 3;
      this.page = 3;
      const data = {
        address: this.address,
        unit: this.unit,
        radio: this.radio,
        first__name: this.first__name,
        last__name: this.last__name,
        email: this.email,
        phone: this.phone,
        homeValue: this.homeValue.slice(1)
      };
      axios
        .post("https://api.homefunds.com/email", data)

        //
        .then(r => console.log(r));

      /*
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      );
      document.body.appendChild(recaptchaScript);
      */
    }
  }
};
</script>

<style lang="scss" scoped>
//  header step
.header {
  padding: 15px 17px;
  margin-bottom: 36px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.background {
  background: #004e86;
}

.logo {
  height: 38px;
  width: 38px;
}

.pages {
  display: flex;
}

.router-link-active {
  text-decoration: none;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Mark Pro Black";
  width: 32px;
  height: 32px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 114.1%;
  text-align: center;
  color: #ffffff;

  padding: 8px;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
}

.header__contact {
  width: 38px;
  height: 38px;
  background: #3f89f9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 4px rgba(78, 34, 205, 0.3));
}

.link {
  text-decoration: none;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}

.confirm__page {
  width: 32px;
  height: 32px;
  background: #23bf75;
  border: 1px solid #ffffff;
}

.active__page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #244b85;
  border: 1px solid #23bf75;
  box-sizing: border-box;
  border-radius: 50%;
}

.completed {
  display: none;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 114.1%;
  color: #ffffff;
  margin-bottom: 10px;
}

.page {
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}

.listPageThird {
  display: flex;
  li {
    margin-right: 60px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.btnPageThird {
  display: none;
}

.pb-0 {
  margin-bottom: 10px !important;
}

@media (max-width: 767px) {
  .listPageThird {
    display: none;
  }

  .desktop {
    display: none;
  }

  .pt-36 {
    padding-top: 0;
  }

  .fz-28 {
    width: auto !important;
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .fz-28 {
    font-size: 28px;
  }

  .page3 {
    margin-top: 64px !important;
  }

  .pt-36 {
    padding-top: 36px;
  }

  .header {
    padding: 17px 20px 22px;
    max-width: 1320px;
    margin: 0 auto;
    border: none;
  }

  .border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .mobail {
    display: none;
  }

  .width-auto {
    width: auto;
    height: auto;
    margin-right: 20px;
  }

  .pages {
    justify-content: space-around;
    align-items: flex-end;
    width: 55%;
  }

  .page__count {
    font-family: "Mark Pro Bold";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 114.1%;
    text-align: center;
    color: #ffffff;
  }

  .page,
  .link {
    margin-top: 6px;
  }

  .contact__us {
    display: flex;
    flex-direction: column;
    margin-right: 27px;
    width: 162px;

    &--item {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      font-family: "Mark Pro Bold";
      line-height: 18px;
      margin-bottom: 11px;
      display: flex;
      align-items: center;
      img {
        margin-right: 11px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 940px) {
    .completed {
      display: block;
      position: relative;
      font-weight: normal;
      font-size: 12px;
      line-height: 114.1%;
      color: #ffffff;
      margin-bottom: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 87%;
        transform: translate(-50%);
        width: 36%;
        height: 1px;
        background-color: #f1f1f1;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 122%;
        transform: translate(-50%);
        width: 33%;
        height: 1px;
        background-color: #f1f1f1;
      }
    }

    .completed__border {
      position: relative;
      font-weight: normal;
      font-size: 12px;
      line-height: 114.1%;
      color: transparent;
      margin-bottom: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0% !important;
        transform: translate(-50%);
        width: 92% !important;
        height: 1px;
        background-color: #23bf75;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 90% !important;
        transform: translate(-50%);
        width: 100% !important;
        height: 1px;
        background-color: #23bf75;
      }
    }

    .noAccentBorder {
      position: relative;
      font-weight: normal;
      font-size: 12px;
      line-height: 114.1%;
      color: transparent;
      margin-bottom: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0% !important;
        transform: translate(-50%);
        width: 93% !important;
        height: 1px;
        background-color: #f1f1f1;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 90% !important;
        transform: translate(-50%);
        width: 100% !important;
        height: 1px;
        background-color: #f1f1f1;
      }
    }
  }

  @media (min-width: 1030px) {
    .completed {
      &::before {
        width: 43%;
        right: 87%;
      }
      &::after {
        width: 46%;
        left: 129%;
      }
    }

    .noAccentBorder {
      &::before {
        right: 0% !important;
        width: 100% !important;
      }
      &::after {
        left: 100% !important;
        width: 100% !important;
      }
    }

    .completed__border {
      &::before {
        content: "";
        right: 0% !important;
        width: 100% !important;
      }
      &::after {
        content: "";
        left: 100% !important;
        width: 100% !important;
      }
    }
  }
  @media (min-width: 1090px) {
    .completed__border {
      &::before {
        right: 0% !important;
        width: 100% !important;
      }
      &::after {
        left: 100% !important;
        width: 100% !important;
      }
    }

    .completed {
      &::after {
        width: 48%;
        left: 129%;
      }

      &::before {
        width: 50%;
        right: 80%;
      }
    }

    .noAccentBorder {
      &::before {
        right: -3% !important;
        width: 104% !important;
      }
      &::after {
        left: 100% !important;
        width: 110% !important;
      }
    }

    .btnPageThird {
      display: block;
      background: #3f89f9;
      border-radius: 30px;
      font-family: "Source Sans Pro Black";
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 23px;
      text-transform: uppercase;
      color: #ffffff;
      border: none;
      width: 233px;
      height: 60px;
      outline: none;
      cursor: pointer;
    }
  }
  @media (min-width: 1200px) {
    .completed {
      &::before {
        width: 67%;
        right: 72%;
      }
      &::after {
        width: 63%;
        left: 140%;
      }
    }

    .noAccentBorder {
      &::before {
        right: -2% !important;
        width: 114% !important;
      }
      &::after {
        left: 106% !important;
        width: 125% !important;
      }
    }
  }
  @media (min-width: 1280px) {
    .completed__border {
      &::before {
        right: 0% !important;
        width: 112% !important;
      }
      &::after {
        left: 106% !important;
        width: 125% !important;
      }
    }

    .noAccentBorder {
      &::before {
        right: 0% !important;
        width: 116% !important;
      }
      &::after {
        left: 109% !important;
        width: 135% !important;
      }
    }
  }
  @media (min-width: 1320px) {
    .completed__border {
      &::before {
        right: 0% !important;
        width: 118% !important;
      }
      &::after {
        left: 106% !important;
        width: 145% !important;
      }
    }

    .completed {
      &::before {
        width: 71%;
        right: 72%;
      }
      &::after {
        width: 74%;
        left: 145%;
      }
    }

    .noAccentBorder {
      &::before {
        right: -4% !important;
        width: 124% !important;
      }
      &::after {
        left: 109% !important;
        width: 140% !important;
      }
    }
  }
}
// first step
@media (min-width: 768px) {
  .page__first {
    margin-bottom: 84px;
  }
  .form {
    padding: 44px 0px 51px 0px;

    .first {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0px 50px 42px !important;

      .item__title {
        font-size: 22px;
        line-height: 114.1%;
        margin-bottom: 17px;
      }

      .item__title {
        width: 100%;
      }
    }

    .width__70 {
      width: 70%;
    }

    .width__27 {
      width: 27%;
    }

    .second {
      padding: 36px 50px 48px;

      .item__title {
        margin-bottom: 33px;
        font-size: 22px;
        line-height: 114.1%;
        color: #494967;
      }
    }
    .third {
      padding: 38px 50px 48px;
      .item__title {
        font-size: 22px;
        line-height: 114.1%;
        color: #494967;
      }

      input {
        margin-bottom: 17px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .input_mb {
      margin-bottom: 17px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
//second step

.page__second {
  max-width: 520px;
}

.title__zillow__cost {
  margin-top: 24px;
  font-family: "Mark Pro Black";
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 114.1%;
  color: #a7aabf;
  margin-bottom: 11px;
}

.cost {
  font-family: "Mark Pro Bold";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 114.1%;
  color: #494967;
  margin-bottom: 45px;
}

.title__about {
  margin: 26px 0 11px;
  text-align: left;
  font-family: "Mark Pro Black";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 26px;
  color: #494967;
}

.text__about {
  text-align: left;
  font-family: "Source Sans Pro Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #a7aabf;
  margin-bottom: 27px;
}

.shoud__know {
  text-align: left;
  font-family: "Source Sans Pro Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 26px;
  color: #b2b5c7;
}

@media (min-width: 768px) {
  .width__613 {
    width: 613px;
  }

  .form {
    padding: 53px 0 37px;
    margin: 0 auto 98px;
    .form__item {
      padding: 0 50px;
    }

    .second {
      padding-top: 36px;
      padding-bottom: 48px;
    }
  }

  .title__about {
    margin: 28px auto 14px;
    font-size: 22px;
  }

  .intro__second--page {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 50px 24px !important;
  }

  .homeValue {
    width: 100%;
  }

  .intro__page__second {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;
  }

  .title__zillow__cost {
    font-family: "Mark Pro Black";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 114.1%;
    color: #a7aabf;
    text-align: left;
    margin: 0;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .cost {
    margin: 0;
  }

  .table__btn {
    width: 389px !important;
  }

  .shoud__know {
    margin-top: 16px;
    margin-bottom: 36px;
  }
}

// step 3

.page__third {
  .white__ball,
  .yellow__ball,
  .purpule__ball,
  .green__ball {
    display: none;
  }

  position: relative;
  width: calc(100% - 26px);
  max-width: 690px;
  padding: 41px 0 48px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 30px 50px rgba(16, 25, 43, 0.2);
  border-radius: 10px;

  .first__flag,
  .bigFirstFlag {
    position: absolute;
    top: 0;
    left: 0;
  }

  .second__flag,
  .bigSecondFlag {
    position: absolute;
    top: 0;
    right: 0;
  }

  .last__page--title {
    margin-top: 6px;
    margin-bottom: 23px;
    font-family: "Mark Pro Black";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #494967;
    padding: 0 40px;
  }

  .border {
    border-bottom: 1px solid #ededed;
  }

  .saved__money {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 256px;
    height: 80px;
    border: 1px dashed #23bf75;
    border-radius: 5px;
    margin-bottom: 34px;

    .banknotes {
      margin-right: 12px;
    }

    .price {
      font-family: "Mark Pro Bold";
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 46px;
      color: #494967;
    }
  }

  .main__info {
    display: flex;
    flex-direction: column;
    padding: 34px 40px 40px;

    .info__item {
      width: 272px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .info__text {
      font-family: "Mark Pro Bold";
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      color: #494967;
      margin-left: 18px;
    }
  }

  .text__info {
    background: #e9f0fd;
    p {
      text-align: left;
      font-family: "Mark Pro Bold";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      color: #494967;
      padding: 39px 28px;
    }
  }
}

.feedback {
  width: 280px;
  margin: 60px auto 0;
  .avatar__info {
    position: relative;
    display: flex;
  }

  .avatar {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: start;
    border-radius: 50%;
  }

  .avatar__icon {
    position: absolute;
    bottom: 0;
    left: 40px;
    padding: 9px 7px 8px 7px;
    border-radius: 50%;
    background: #23bf75;
  }

  .title__stars {
    margin-left: 13px;
  }

  .avatar__title {
    text-align: left;
    font-family: "Mark Pro Bold";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 6px;
  }

  .star {
    margin-right: 5px;
    margin-bottom: 19px;
    &:last-child {
      margin-right: 0;
    }
  }

  .avatar__text {
    width: 244px;
    margin-left: 42px;
    text-align: left;
  }
}

.company {
  padding: 20px;
  width: 150px;
  margin-top: 60px;
  margin: 60px auto 0;
  padding: 20px;
  border-radius: 6px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
}

@media (max-width: 767px) {
  .bigFirstFlag,
  .bigSecondFlag {
    display: none;
  }

  .bottom__balls--right,
  .bottom__balls--left {
    display: none;
  }

  .price {
    font-family: "Mark Pro Bold";
    font-style: normal;
    font-weight: 800;
    font-size: 28px !important;
    line-height: 46px;
    color: #494967;
  }
}

@media (min-width: 375px) {
  .feedback {
    .avatar__text {
      width: 244px;
      margin-left: 75px;
    }
  }
  .btn__submit {
    width: 311px;
  }

  .page__third {
    .main__info {
      padding-left: 46px;

      .info__text {
        font-size: 16px;
      }
    }
  }

  .feedback {
    width: 335px;
  }
}

@media (min-width: 768px) {
  .intro__title {
    margin: 0px auto 38px;
  }

  .page__third {
    .white__ball {
      display: block;
      position: absolute;
      top: 123px;
      left: 15px;
      zoom: 1.2;
      transform: rotate(-16deg);
    }

    .last__page--title {
      padding: 0 15px;
    }

    .yellow__ball {
      display: block;
      position: absolute;
      top: 59px;
      left: -46px;
      zoom: 1.2;
      transform: rotate(-16deg);
    }
    .purpule__ball {
      display: block;
      position: absolute;
      zoom: 1.4;
      top: 132px;
      left: -46px;
      transform: rotate(-14deg);
    }

    .green__ball {
      display: block;
      position: absolute;
      top: 75px;
      right: -75px;
      zoom: 1.5;
      transform: rotate(16deg);
    }
  }

  .feedback {
    .avatar__text {
      width: 300px;
      text-align: left;
    }
  }

  .btn__submit {
    width: 497px;
  }

  .first__flag,
  .second__flag {
    display: none;
  }

  .bottom__balls--right {
    display: block;
    position: absolute;
    bottom: 0;
    right: -30px;
  }

  .bottom__balls--left {
    display: block;
    position: absolute;
    bottom: 0;
    left: -38px;
  }

  .form {
    padding: 41px 0 48px;
    margin: 0 auto 80px;

    .third {
      padding-top: 38px;
      padding-bottom: 48px;
      .item__title {
        margin-bottom: 17px;
      }
    }
  }

  .page__third {
    position: relative;
    .last__page--title {
      margin: 0 auto;
      width: 356px;
      font-size: 22px;
      margin-bottom: 22px;
    }

    .text__info p {
      text-align: center;
      padding: 39px 60px;
    }

    .saved__money {
      width: 345px;
      height: 96px;

      .banknotes {
        width: 64px;
        height: 64px;
        margin-right: 17px;
      }
    }

    .price {
      font-size: 48px;
      line-height: 61px;
    }

    .main__info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .info__item {
        width: 30%;
        margin-bottom: 0;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-bottom: 35px;
        }

        img {
          margin-bottom: 10px;
        }

        .info__text {
          text-align: left;
        }
      }
    }
  }

  .feedback {
    margin: 0;
  }

  .company {
    width: auto;
    margin: 0px;
  }

  .before__footer {
    margin: 80px auto 73px;
    width: 740px;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
}

// page 4

.page4__text {
  color: #494967;
  padding: 0 30px;
}

@media (min-width: 768px) {
  .page4 {
    min-height: 60vh;
  }

  .page4__text {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .page4 {
    min-height: 40vh;
  }

  .page4__text {
    text-align: left;
    padding: 0 20px;
  }
}
</style>
