import Vue from "vue";
import App from "./App.vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import "./style.scss";

Vue.use(Vuelidate);
Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({ render: h => h(App) }).$mount("#app");
